// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  /*
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
*/

  --ion-color-primary: #204F61;
  --ion-color-primary-rgb: 32,79,97;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1c4655;
  --ion-color-primary-tint: #366171;

  /** secondary **/
  /*
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
*/
  --ion-color-secondary: #347B8F;
  --ion-color-secondary-rgb: 52,123,143;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2e6c7e;
  --ion-color-secondary-tint: #48889a;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** exme Blue dark **/
  --ion-color-exme-dark: #204F61;
  --ion-color-exme-dark-rgb: 32,79,97;
  --ion-color-exme-dark-contrast: #ffffff;
  --ion-color-exme-dark-contrast-rgb: 255,255,255;
  --ion-color-exme-dark-shade: #1c4655;
  --ion-color-exme-dark-tint: #366171;

  /** exme Blue light **/
  --ion-color-exme-light: #347B8F;
  --ion-color-exme-light-rgb: 52,123,143;
  --ion-color-exme-light-contrast: #ffffff;
  --ion-color-exme-light-contrast-rgb: 255,255,255;
  --ion-color-exme-light-shade: #2e6c7e;
  --ion-color-exme-light-tint: #48889a;

  /** exme Gray light **/
  --ion-color-exme-gray-light: #67808b;
  --ion-color-exme-gray-light-rgb: 103,128,139;
  --ion-color-exme-gray-light-contrast: #ffffff;
  --ion-color-exme-gray-light-contrast-rgb: 255,255,255;
  --ion-color-exme-gray-light-shade: #5b717a;
  --ion-color-exme-gray-light-tint: #768d97;

  /** exme Gray dark **/
  --ion-color-exme-gray-dark: #3e5968;
  --ion-color-exme-gray-dark-rgb: 62,89,104;
  --ion-color-exme-gray-dark-contrast: #ffffff;
  --ion-color-exme-gray-dark-contrast-rgb: 255,255,255;
  --ion-color-exme-gray-dark-shade: #374e5c;
  --ion-color-exme-gray-dark-tint: #516a77;

  /** exme Red light **/
  --ion-color-exme-red-light: #b22527;
  --ion-color-exme-red-light-rgb: 178,37,39;
  --ion-color-exme-red-light-contrast: #ffffff;
  --ion-color-exme-red-light-contrast-rgb: 255,255,255;
  --ion-color-exme-red-light-shade: #9d2122;
  --ion-color-exme-red-light-tint: #ba3b3d;

  /** exme Red dark **/
  --ion-color-exme-red-dark: #771916;
  --ion-color-exme-red-dark-rgb: 119,25,22;
  --ion-color-exme-red-dark-contrast: #ffffff;
  --ion-color-exme-red-dark-contrast-rgb: 255,255,255;
  --ion-color-exme-red-dark-shade: #691613;
  --ion-color-exme-red-dark-tint: #85302d;

  /** exme Yellow dark **/
  --ion-color-exme-yellow-dark: #dd8b28;
  --ion-color-exme-yellow-dark-rgb: 221,139,40;
  --ion-color-exme-yellow-dark-contrast: #000000;
  --ion-color-exme-yellow-dark-contrast-rgb: 0,0,0;
  --ion-color-exme-yellow-dark-shade: #c27a23;
  --ion-color-exme-yellow-dark-tint: #e0973e;

  /** exme Yellow light **/
  --ion-color-exme-yellow-light: #ffc221;
  --ion-color-exme-yellow-light-rgb: 255,194,33;
  --ion-color-exme-yellow-light-contrast: #000000;
  --ion-color-exme-yellow-light-contrast-rgb: 0,0,0;
  --ion-color-exme-yellow-light-shade: #e0ab1d;
  --ion-color-exme-yellow-light-tint: #ffc837;

  --ion-color-icon: #204F61;
  --ion-color-icon-rgb: 32,79,97;
  --ion-color-icon-contrast: #ffffff;
  --ion-color-icon-contrast-rgb: 255,255,255;
  --ion-color-icon-shade: #1c4655;
  --ion-color-icon-tint: #366171;

  --ion-color-text-87: #204F61;

  --ion-color-yellow-white: #dd8b28;

  --ion-color-light-blue-white: #347b8f;

  --ion-color-red-white: #771916;

  --ion-color-exme-red: #b22527;

  --ion-color-highlight: #2dd36f;

  --ion-color-black-white: black;

  --ion-color-gray-white: #666666;

  --ion-color-gray-blue: #424242;

  --ion-color-light-yellow-white: rgb(255, 194, 33);

  --ion-color-white-blue: white;

  --ion-color-dark-blue-blue: #204F61;

  --ion-color-white-black: white;

  --ion-color-red-blue: #771916;

  --ion-color-black-white: black;

  --ion-color-white-gray: white;

  --ion-color-light-gray-white: rgb(115, 115, 115);

  --ion-color-dark-blue-white: #204F61;

  --ion-color-light-blue-blue: #347b8f;

  --ion-color-black-blue: black;

  --ion-color-swiper-active: rgb(0, 122, 255);

  --ion-color-swiper-inactive: rgb(0, 0, 0);

  --ion-color-dark-blue-black: #204F61;
}

.ion-color-exme-dark {
  --ion-color-base: var(--ion-color-exme-dark);
  --ion-color-base-rgb: var(--ion-color-exme-dark-rgb);
  --ion-color-contrast: var(--ion-color-exme-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-dark-shade);
  --ion-color-tint: var(--ion-color-exme-dark-tint);
}

.ion-color-exme-light {
  --ion-color-base: var(--ion-color-exme-light);
  --ion-color-base-rgb: var(--ion-color-exme-light-rgb);
  --ion-color-contrast: var(--ion-color-exme-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-light-shade);
  --ion-color-tint: var(--ion-color-exme-light-tint);
}

.ion-color-exme-gray-light {
  --ion-color-base: var(--ion-color-exme-gray-light);
  --ion-color-base-rgb: var(--ion-color-exme-gray-light-rgb);
  --ion-color-contrast: var(--ion-color-exme-gray-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-gray-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-gray-light-shade);
  --ion-color-tint: var(--ion-color-exme-gray-light-tint);
}

.ion-color-exme-gray-dark {
  --ion-color-base: var(--ion-color-exme-gray-dark);
  --ion-color-base-rgb: var(--ion-color-exme-gray-dark-rgb);
  --ion-color-contrast: var(--ion-color-exme-gray-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-gray-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-gray-dark-shade);
  --ion-color-tint: var(--ion-color-exme-gray-dark-tint);
}

.ion-color-exme-red-light {
  --ion-color-base: var(--ion-color-exme-red-light);
  --ion-color-base-rgb: var(--ion-color-exme-red-light-rgb);
  --ion-color-contrast: var(--ion-color-exme-red-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-red-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-red-light-shade);
  --ion-color-tint: var(--ion-color-exme-red-light-tint);
}

.ion-color-exme-red {
  --ion-color-base: var(--ion-color-exme-red);
  --ion-color-base-rgb: var(--ion-color-exme-red-rgb);
  --ion-color-contrast: var(--ion-color-exme-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-red-shade);
  --ion-color-tint: var(--ion-color-exme-red-tint);
}

.ion-color-exme-red-dark {
  --ion-color-base: var(--ion-color-exme-red-dark);
  --ion-color-base-rgb: var(--ion-color-exme-red-dark-rgb);
  --ion-color-contrast: var(--ion-color-exme-red-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-red-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-red-dark-shade);
  --ion-color-tint: var(--ion-color-exme-red-dark-tint);
}

.ion-color-exme-yellow-dark {
  --ion-color-base: var(--ion-color-exme-yellow-dark);
  --ion-color-base-rgb: var(--ion-color-exme-yellow-dark-rgb);
  --ion-color-contrast: var(--ion-color-exme-yellow-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-yellow-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-yellow-dark-shade);
  --ion-color-tint: var(--ion-color-exme-yellow-dark-tint);
}

.ion-color-exme-yellow-light {
  --ion-color-base: var(--ion-color-exme-yellow-light);
  --ion-color-base-rgb: var(--ion-color-exme-yellow-light-rgb);
  --ion-color-contrast: var(--ion-color-exme-yellow-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-exme-yellow-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-exme-yellow-light-shade);
  --ion-color-tint: var(--ion-color-exme-yellow-light-tint);
}

body.dark {
  /*
   * Dark Colors
   * -------------------------------------------
   */

   .ion-color-icon {
    --ion-color-base: #82d9f3;
    --ion-color-base-rgb: 130,217,243;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0,0,0;
    --ion-color-shade: #72bfd6;
    --ion-color-tint: #8fddf4;
  }

  .ion-color-text {
    --ion-color-base: #fff;
    --ion-color-base-rgb: 255,255,255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0,0,0;
    --ion-color-shade: #e0e0e0;
    --ion-color-tint: #ffffff;
  }

    --ion-color-secondary: #82d9f3;
    --ion-color-secondary-rgb: 130,217,243;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #72bfd6;
    --ion-color-secondary-tint: #8fddf4;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #82d9f3;
    --ion-color-success-rgb: 130,217,243;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #72bfd6;
    --ion-color-success-tint: #8fddf4;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #82d9f3;
    --ion-color-danger-rgb: 130,217,243;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0,0,0;
    --ion-color-danger-shade: #72bfd6;
    --ion-color-danger-tint: #8fddf4;

    --ion-color-dark: #ffffff;
    --ion-color-dark-rgb: 255,255,255;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #e0e0e0;
    --ion-color-dark-tint: #ffffff;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

	--ion-color-text: #ffffff;
	--ion-color-text-rgb: 255,255,255;
	--ion-color-text-contrast: #000000;
	--ion-color-text-contrast-rgb: 0,0,0;
	--ion-color-text-shade: #e0e0e0;
	--ion-color-text-tint: #ffffff;

  --ion-color-text-87: rgba(var(--ion-color-text-rgb), 0.87);

  --ion-color-yellow-white: var(--ion-color-text-87);

  --ion-color-light-blue-white: var(--ion-color-text-87);

  --ion-color-red-white: var(--ion-color-text-87);

  --ion-color-highlight: var(--ion-color-primary);

  --ion-color-black-white: var(--ion-color-text-87);

  --ion-color-gray-white: var(--ion-color-text-87);

  --ion-color-gray-blue: var(--ion-color-primary);

  --ion-color-light-yellow-white: var(--ion-color-text-87);

  --ion-color-white-blue: var(--ion-color-primary);

  --ion-color-dark-blue-blue: var(--ion-color-primary);

  --ion-color-white-black: black;

  --ion-color-red-blue: var(--ion-color-primary);

  --ion-color-black-white: var(--ion-color-text-87);

  --ion-color-white-gray: #191919;

  --ion-color-light-gray-white: var(--ion-color-text-87);

  --ion-color-dark-blue-white: var(--ion-color-text-87);

  --ion-color-light-blue-blue: var(--ion-color-primary);

  --ion-color-black-blue: var(--ion-color-primary);

  --ion-color-swiper-active: var(--ion-color-primary);

  --ion-color-swiper-inactive: var(--ion-color-text-87);

  --ion-color-dark-blue-black: black;

    --ion-color-icon: #82d9f3;
    --ion-color-icon-rgb: 130,217,243;
    --ion-color-icon-contrast: #000000;
    --ion-color-icon-contrast-rgb: 0,0,0;
    --ion-color-icon-shade: #72bfd6;
    --ion-color-icon-tint: #8fddf4;

    --ion-color-primary: #82d9f3;
    --ion-color-primary-rgb: 130,217,243;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #72bfd6;
    --ion-color-primary-tint: #8fddf4;

    --ion-color-exme-dark: #82d9f3;
    --ion-color-exme-dark-rgb: 130,217,243;
    --ion-color-exme-dark-contrast: #000000;
    --ion-color-exme-dark-contrast-rgb: 0,0,0;
    --ion-color-exme-dark-shade: #72bfd6;
    --ion-color-exme-dark-tint: #8fddf4;

    --ion-color-exme-light: #82d9f3;
    --ion-color-exme-light-rgb: 130,217,243;
    --ion-color-exme-light-contrast: #000000;
    --ion-color-exme-light-contrast-rgb: 0,0,0;
    --ion-color-exme-light-shade: #72bfd6;
    --ion-color-exme-light-tint: #8fddf4;

    --ion-color-exme-yellow-light: #ffffff;
    --ion-color-exme-yellow-light-rgb: 255,255,255;
    --ion-color-exme-yellow-light-contrast: #000000;
    --ion-color-exme-yellow-light-contrast-rgb: 0,0,0;
    --ion-color-exme-yellow-light-shade: #e0e0e0;
    --ion-color-exme-yellow-light-tint: #ffffff;

    --ion-color-exme-yellow-dark: var(--ion-color-text-87);
    --ion-color-exme-yellow-dark-rgb: 255,255,255;
    --ion-color-exme-yellow-dark-contrast: #000000;
    --ion-color-exme-yellow-dark-contrast-rgb: 0,0,0;
    --ion-color-exme-yellow-dark-shade: #e0e0e0;
    --ion-color-exme-yellow-dark-tint: #ffffff;

    --ion-color-exme-red-dark: #82d9f3;
    --ion-color-exme-red-dark-rgb: 130,217,243;
    --ion-color-exme-red-dark-contrast: #000000;
    --ion-color-exme-red-dark-contrast-rgb: 0,0,0;
    --ion-color-exme-red-dark-shade: #72bfd6;
    --ion-color-exme-red-dark-tint: #8fddf4;

    --swiper-theme-color: #82d9f3;
    --swiper-theme-color-rgb: 130,217,243;
    --swiper-theme-color-contrast: #000000;
    --swiper-theme-color-contrast-rgb: 0,0,0;
    --swiper-theme-color-shade: #72bfd6;
    --swiper-theme-color-tint: #8fddf4;

    --ion-color-exme-red-light: #82d9f3;
    --ion-color-exme-red-light-rgb: 130,217,243;
    --ion-color-exme-red-light-contrast: #000000;
    --ion-color-exme-red-light-contrast-rgb: 0,0,0;
    --ion-color-exme-red-light-shade: #72bfd6;
    --ion-color-exme-red-light-tint: #8fddf4;

  --ion-color-mode: #121212;
	--ion-color-mode-rgb: 18,18,18;
	--ion-color-mode-contrast: #ffffff;
	--ion-color-mode-contrast-rgb: 255,255,255;
	--ion-color-mode-shade: #101010;
	--ion-color-mode-tint: #2a2a2a;

	--ion-color-exme-red: #cf6679;
	--ion-color-exme-red-rgb: 207,102,121;
	--ion-color-exme-red-contrast: #000000;
	--ion-color-exme-red-contrast-rgb: 0,0,0;
	--ion-color-exme-red-shade: #b65a6a;
	--ion-color-exme-red-tint: #d47586;

  --ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-contrast-contrast: #ffffff;
	--ion-color-contrast-contrast-rgb: 255,255,255;
	--ion-color-contrast-shade: #000000;
	--ion-color-contrast-tint: #1a1a1a;




  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /*.ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }*/


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  
    --ion-background-color: var(--ion-color-mode);
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: var(--ion-color-mode);

    --ion-toolbar-background: var(--ion-color-mode);

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  
}
