/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~animate.css/animate.min.css';


ion-header.md {
  ion-toolbar:first-child {
      //--padding-top: 27px;
      height: 66px;
  }
}

.rate-button-empty {
    width: 40px;
//    max-width: 20%;
    min-width: auto !important;
    height: 40px;
    margin: 0 !important;
     background-image:url('./assets/icon/Star-Empty-icon.png');
     background-repeat: no-repeat;
     background-position: center;
     border: none;
     .button-inner{
       visibility: hidden;
     }
}

.rate-button-full {
       width: 40px;
//       max-width: 20%;
       min-width: auto !important;
       height: 40px;
       margin: 0 !important;
       background-image:url('./assets/icon/Star-Full-icon.png');
       background-repeat: no-repeat;
       background-position: center;
       border: none;
       .button-inner{
         visibility: hidden;
       }


  }

  .alertstar{

    border-radius: 8px;
      .alert-wrapper{ border-radius: 8px; }
      .alert-button-group{
        padding:0;
        margin-top: 10px;
        flex-direction: row;
        justify-content: space-around;
        flex-direction: row; justify-content: space-around;
        display: flex;
        flex-wrap: wrap;
      }
  }

// FONTS DATA FROM https://google-webfonts-helper.herokuapp.com/fonts/yanone-kaffeesatz?subsets=latin

/* archivo-regular - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/archivo-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Archivo Regular'), local('Archivo-Regular'),
       url('./assets/fonts/archivo-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-regular.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/archivo-v6-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Italic'), local('Archivo-Italic'),
       url('./assets/fonts/archivo-v6-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-italic.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-500 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/archivo-v6-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Archivo Medium'), local('Archivo-Medium'),
       url('./assets/fonts/archivo-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-500.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-500italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/archivo-v6-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Medium Italic'), local('Archivo-MediumItalic'),
       url('./assets/fonts/archivo-v6-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-500italic.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-600 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/archivo-v6-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Archivo SemiBold'), local('Archivo-SemiBold'),
       url('./assets/fonts/archivo-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-600.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-600italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/archivo-v6-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo SemiBold Italic'), local('Archivo-SemiBoldItalic'),
       url('./assets/fonts/archivo-v6-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-600italic.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-700 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/archivo-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Archivo Bold'), local('Archivo-Bold'),
       url('./assets/fonts/archivo-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-700.svg#Archivo') format('svg'); /* Legacy iOS */
}
/* archivo-700italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/archivo-v6-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Bold Italic'), local('Archivo-BoldItalic'),
       url('./assets/fonts/archivo-v6-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-v6-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-v6-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-v6-latin-700italic.svg#Archivo') format('svg'); /* Legacy iOS */
}

/* archivo-narrow-regular - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/archivo-narrow-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Regular'), local('ArchivoNarrow-Regular'),
       url('./assets/fonts/archivo-narrow-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-regular.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-500 - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/archivo-narrow-v11-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Medium'), local('ArchivoNarrow-Medium'),
       url('./assets/fonts/archivo-narrow-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-500.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/archivo-narrow-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Italic'), local('ArchivoNarrow-Italic'),
       url('./assets/fonts/archivo-narrow-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-500italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/archivo-narrow-v11-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Medium Italic'), local('ArchivoNarrow-MediumItalic'),
       url('./assets/fonts/archivo-narrow-v11-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-500italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-600italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/archivo-narrow-v11-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow SemiBold Italic'), local('ArchivoNarrow-SemiBoldItalic'),
       url('./assets/fonts/archivo-narrow-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-600italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-600 - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/archivo-narrow-v11-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow SemiBold'), local('ArchivoNarrow-SemiBold'),
       url('./assets/fonts/archivo-narrow-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-600.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700 - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/archivo-narrow-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Bold'), local('ArchivoNarrow-Bold'),
       url('./assets/fonts/archivo-narrow-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-700.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700italic - latin */
@font-face {
  font-family: 'Archivo Narrow';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/archivo-narrow-v11-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Archivo Narrow Bold Italic'), local('ArchivoNarrow-BoldItalic'),
       url('./assets/fonts/archivo-narrow-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/archivo-narrow-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/archivo-narrow-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/archivo-narrow-v11-latin-700italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}

/* cairo-200 - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/cairo-v6-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Cairo ExtraLight'), local('Cairo-ExtraLight'),
       url('./assets/fonts/cairo-v6-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-200.svg#Cairo') format('svg'); /* Legacy iOS */
}
/* cairo-regular - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/cairo-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cairo'), local('Cairo-Regular'),
       url('./assets/fonts/cairo-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-regular.svg#Cairo') format('svg'); /* Legacy iOS */
}
/* cairo-300 - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/cairo-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Cairo Light'), local('Cairo-Light'),
       url('./assets/fonts/cairo-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-300.svg#Cairo') format('svg'); /* Legacy iOS */
}
/* cairo-600 - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/cairo-v6-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'),
       url('./assets/fonts/cairo-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-600.svg#Cairo') format('svg'); /* Legacy iOS */
}
/* cairo-700 - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/cairo-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Cairo Bold'), local('Cairo-Bold'),
       url('./assets/fonts/cairo-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-700.svg#Cairo') format('svg'); /* Legacy iOS */
}
/* cairo-900 - latin */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/cairo-v6-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Cairo Black'), local('Cairo-Black'),
       url('./assets/fonts/cairo-v6-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/cairo-v6-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/cairo-v6-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/cairo-v6-latin-900.svg#Cairo') format('svg'); /* Legacy iOS */
}


/* signika-300 - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/signika-v10-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Signika Light'), local('Signika-Light'),
       url('./assets/fonts/signika-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/signika-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/signika-v10-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/signika-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/signika-v10-latin-300.svg#Signika') format('svg'); /* Legacy iOS */
}
/* signika-600 - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/signika-v10-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Signika SemiBold'), local('Signika-SemiBold'),
       url('./assets/fonts/signika-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/signika-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/signika-v10-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/signika-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/signika-v10-latin-600.svg#Signika') format('svg'); /* Legacy iOS */
}
/* signika-regular - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/signika-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Signika Regular'), local('Signika-Regular'),
       url('./assets/fonts/signika-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/signika-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/signika-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/signika-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/signika-v10-latin-regular.svg#Signika') format('svg'); /* Legacy iOS */
}
/* signika-700 - latin */
@font-face {
  font-family: 'Signika';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/signika-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Signika Bold'), local('Signika-Bold'),
       url('./assets/fonts/signika-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/signika-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/signika-v10-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/signika-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/signika-v10-latin-700.svg#Signika') format('svg'); /* Legacy iOS */
}

/* yanone-kaffeesatz-200 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-200.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-300 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-300.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-500 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-500.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-600 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-600.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}
/* yanone-kaffeesatz-700 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/yanone-kaffeesatz-v14-latin-700.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

* {
  font-family: 'Archivo' !important;
}

progress[value] {
  margin-left: 1px;
  margin-right: 1px;
}

  progress[value] {
    -webkit-appearance: none;
     appearance: none;
     width: 100%;
     height: 10px;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #f3f3f3;
    border-radius: 9px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  progress[value]::-webkit-progress-value {
    background-color: var(--ion-color-light-blue-blue);
  //  background-color: #00BAE9;
  //	  background-color: #33cd5f;
    border-radius: 9px;
  }
  progress[value].inhibited {
    -webkit-appearance: none;
     appearance: none;
     width: 100%;
     height: 10px;
  }

  progress[value].inhibited::-webkit-progress-bar {
    background-color: #f3f3f3;
    border-radius: 9px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  progress[value].inhibited::-webkit-progress-value {
    background-color: #b22527;
  //	  background-color: #33cd5f;
    border-radius: 9px;
  }

  h1 {
    font-family: "Archivo" ;
    font-weight: 600px;
    font-size: 18px;
    color: var(--ion-color-icon);
  }
  h2 {
    font-family: "Archivo Narrow" ;
    font-weight: 500px;
    font-size: 16px;
    color: var(--ion-color-icon);
  }
  h3 {
    font-family: "Archivo Narrow" ;
    font-size: 14px;
    font-weight: 400px;
    color: var(--ion-color-exme-dark);
  }
  h4 {
    font-family: "Archivo Narrow" ;
    font-size: 12px;
    font-weight: 300px;
    color: var(--ion-color-icon);
  }


  ion-title {
    color: var(--ion-color-icon);
    text-align: center;
    font-family: 'Signika';
    font-weight: 700;
  }

  button {
    span{
      button-inner{
      background: var(--ion-color-exme-dark);
  }}

  }

  .exme-button-full{
    background-color: var(--ion-color-exme-dark);
    font-family: 'Signika';
    font-weight: 300;
    color: white  ;
    border-radius: 9px;
    margin-left: 2px !important;
    margin-right: 0px !important;
  }

  .exme-button-red{
    background-color: var(--ion-color-exme-red-dark);
    font-family: 'Signika';
    font-weight: 300;
    color: white  ;
    border-radius: 9px;
    margin-left: 2px !important;
    margin-right: 0px !important;
  }


  .exme-icon-full {
    color: var(--ion-color-exme-dark);
  }

  .segment-button-checked{
    color: var(--ion-color-icon) !important;
    --color-checked: var(--ion-color-icon);
  }
  .segment-button{
    color: var(--ion-color-exme-dark);
    border: none;

  }
  .segment-button-indicator-background{
    background-color: var(--ion-color-exme-dark);
  }

  body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }



  ion-popover {
    --background: rgba(40, 173, 218, 0.6);
    --backdrop-opacity: 0.6;
    --color: white;
    --width: 300px;
  }


  .v-col-added-modal {
    --height: 50%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .add-col-modal {
    //align-items: start;
    --height: 85%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .v-add-col-toolbar {
    --background: var(--ion-color-exme-dark);
    --color: white;
  }

  .album-view-modal {
    --height: 80%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  font {
    color: var(--ion-color-black-white)
  }

  ion-segment-button{
    --color: var(--ion-color-gray-white)

  }

  .alert-buttons {
    button.alert-button{
      color: var(--ion-color-icon);
    }

    div.alert-radio-icon {
      border-color: var(--ion-color-icon);
      --ion-color-primary: var(--ion-color-icon)
    }

    [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
      color: var(--ion-color-black-white);
    }

    div.alert-message {
      color: var(--ion-color-light-gray-white);
    }

    input.alert-input {
      --ion-color-primary: var(--ion-color-icon)
    }

    div.alert-wrapper {
      background-color: var(--ion-color-white-gray)
    }
  }

  ion-item {
    --highlight-backgroud: var(--ion-color-highlight) 
  }

  .white {
    color: var(--ion-color-text-87);
  }

  .message-right > font, .message > font, .message-right {
    color: rgba(0,0,0, 0.85);
  }

  ion-menu-button {
    color: var(--ion-color-text-87);
  }





  
